/* home.css */

.home-container{
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 60px 20px;
  margin-top: 60px;
}

.home-content-top{
  display: flex;
  flex-direction: column-reverse;
}

.home-intro{
  width: 100%;
  box-shadow: #959da533 0px 8px 24px;
  background-color: #ffffff;
  padding: 10px 30px;
  border-radius: 12px;
  height: auto;
  margin-top: 30px;
}

.home-links{
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  align-items: center;
}

.link{
  height: 26px;
  margin-right: 30px;
}

.home-profile{
  width: 100%;
  height: 200px;
  filter: grayscale(0%);
  transition: 2s;
  border-radius: 12px; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-image{
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 12px;
  box-shadow: #959da533 0px 8px 24px;
}

.home-image:hover{
  filter: grayscale(100%);
  transition: 2s;
}

.home-content-bottom{
  width: 100%;
  box-shadow: #959da533 0px 8px 24px;
  background-color: #ffffff;
  padding: 10px 30px;
  margin-top: 30px;
  border-radius: 12px;
}

.home-container h1{
  font-weight: 900;
  font-size: 1.6rem;
  margin-bottom: 8px;
  margin-top: 10px;
}

.home-container h2{
  font-weight: 900;
  font-size: 1.2rem;
  margin-bottom: 8px;
  margin-top: 10px;
}

.home-container p, .home-container li{
  font-size: 0.8rem;
  line-height: 1.4rem;
  list-style: none;
}




@media only screen and (min-width: 768px) {
  .home-container{
    padding: 60px 100px;
    margin-top: 60px;
  }

  .home-content-top{
    flex-direction: row;
    justify-content: space-between;
    align-items: top;
  }

  .home-profile{
    width: 200px;
  }

  .home-intro{
    height: auto;
    width: 680px;
    margin-right: 30px;
    margin-top: 0;
  }


  .link{
    height: 30px;
    margin-right: 30px;
  }

  .home-content-bottom{
    width: 100%;  
  }

  .home-container h1{
    font-weight: 900;
    font-size: 2rem;
    margin-bottom: 8px;
    color: #333;
  }

  .home-container h2{
    font-weight: 800;
    font-size: 1.4rem;
    margin-bottom: 8px;
    margin-top: 30px;
    color: #333;
  }

  .home-container p, .home-container li{
    font-size: 1rem;
    line-height: 1.5rem;
    color: #333;
  }
}


@media only screen and (min-width: 1000px) {
  .home-container{
    padding: 60px 200px;
  }
}