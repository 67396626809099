/* Articles Page Styles */
.articles-container{
  width: 100%;
  min-height: 100vh;
  padding: 60px 20px;
  margin-top: 60px;
}

.articles-card-container{
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
}


.articles-container h1{
  text-align: center;
  color: #333;
  font-weight: 900;
  font-size: 1.6rem;
}

.article{
  width: 100%;
  border-radius: 5px;
  margin-top: 30px;
  flex-wrap: wrap;
}

.article h2{
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  margin: 5px 0;
  text-decoration: underline;
  color: #333333;
}

.article p{
  bottom: 0;
  font-size: 0.8rem;
  color: #333333;
  line-height: 1.2rem;
}

.article:hover p{
 text-decoration: underline;
}

.articles-container a{
  text-decoration: none;
}

.articles-container .article-image{
  background-color: #333333;
  box-shadow: #959da533 0px 8px 24px;
  object-fit: cover;
  height: 240px;
  width: 100%;
  border-radius: 5px;
}


@media only screen and (min-width: 568px) {
  .articles-card-container {
    grid-template-columns: repeat(2, 1fr); 
  }
}


@media only screen and (min-width: 768px) {
  .articles-container{
    padding: 60px 100px;
    margin-top: 60px;
  }
  

  .articles-container h1{
    font-weight: 900;
    font-size: 2rem;
  }

  .articles-container h2{
    font-weight: 700;
    font-size: 1.1rem;
  }

  .articles-container p{
    font-size: 0.9rem;
  }
}


@media only screen and (min-width: 1000px) {
  .articles-container{
    padding: 60px 200px;
  }

  .articles-card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}