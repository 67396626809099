/* Fixed Navbar Styles */
.navbar {
  display: flex;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
  padding: 15px 20px;
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 4px 6px #0000001a;
}

/* Adjust the top padding of your page's content */
body {
  padding-top: 60px; /* Adjust based on the height of your navbar */
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #4f4f4f;
}

/* Burger Icon for Mobile */
.burger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 22px;
  height: 1px;
  background-color: white;
  margin: 4px 0;
  transition: 0.4s;
  border-radius: 2px;
}

.navbar h2{
  font-weight: 700;
  font-size: 1rem;
}

@media only screen and (min-width: 768px) {
  .navbar{
    padding: 15px 100px;
  }
}

@media only screen and (min-width: 1000px) {
  .navbar{
    padding: 15px 200px;
  }

  .navbar h2{
    font-weight: 700;
    font-size: 1.2rem;
  }
}


/* Responsive Design */
@media (max-width: 768px) {
  .nav-links {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #000000;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transform: translateX(100%);
    transition: transform 0.4s ease-in-out;
    padding-top: 60px;
  }

  .nav-links.open {
    transform: translateX(0%);
  }

  .burger {
    display: flex;
  }

  .nav-links li {
    font-size: 1.5rem;
    padding: 10px;
    margin: 16px 0;
  }
}