/* Global Styles */
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  background-color: #f5f6f7;
  margin: 0;
  padding: 0;
}

/* Button Styling */
button {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #575757;
}

li{
  list-style: none;
}